<template>
  <div class="no-data-found">
    <img
      src="../assets/images/img_no-data-found.svg"
      alt="कुनै डाटा भेटिएन"
      height="166"
      width="166"
      class="mb-16"
    />
    <span>कुनै डाटा भेटिएन</span>
  </div>
</template>

<style scoped lang="scss">
.no-data-found {
  align-items: center;
  background: $neutrals-white;
  /* background: linear-gradient(
    148.53deg,
    rgba(108, 188, 252, 0.2) -0.38%,
    rgba(69, 141, 252, 0.2) 101.5%
  ); */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 0;
  height: 100%;
  span {
    color: rgba($neutrals-black, 0.5);
    display: block;
    font-size: 20px;
    font-family: "Mukta-SemiBold";
    margin: 0 auto;
  }
}
</style>
